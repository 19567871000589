<template>
    <div id="notfound">
        <section class="section">
            <div class="container">
                <h1 class="title is-5 has-text-centered">Unauthorized</h1>
                <div class="has-text-centered">
                    <button class="button" @click="gohome">
           <span class="icon">
              <font-awesome-icon icon="login"/>
            </span>
                        <span>
                Login Page
            </span>

                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    gohome() {
      this.$router.replace("/");
    }
  },
  mounted(){
     this.$router.replace("/");
  }
};
</script>

<style scoped>
html {
  height: 100%;
}

body {
  min-height: 100%;
}

#notfound {
  margin-top: 100px;
  /* padding-top: 10%;
  height: 100%;
  background: aquamarine; */
}
</style>
